module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-react-helmet-async/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-next-seo/gatsby-browser.js'),
      options: {"plugins":[],"title":"That Mexican Place | %s","titleTemplate":"That Mexican Place | %s ","description":"That Mexican Place is a Mexican restaurant chain located in California. Mexican food near you. | %s","canonical":"https://www.thatmexicanplace.net/","defaultOpenGraphImageHeight":1200,"defaultOpenGraphImageWidth":1200,"openGraph":{"type":"website","locale":"en_US","url":"https://www.thatmexicanplace.net","title":"That Mexican Place","description":"That Mexican Place is a Mexican restaurant located at Escondido, CA. Mexican food near Escondido.","images":[{"url":"https://www.thatmexicanplace.net/logo/logo.png","width":800,"height":600,"alt":"That Mexican Place Logo"}],"site_name":"That Mexican Place"},"twitter":{"handle":"@thatmexicanplace","site":"@thatmexicanplace","cardType":"summary_large_image"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"That Mexican Place","short_name":"That Mexican Place","description":"That Mexican Place is a Mexican restaurant located at Escondido, CA. Mexican food near Escondido","start_url":"/","lang":"en","background_color":"#e53e3e","theme_color":"#2f855a","display":"minimal-ui","icon":"src/images/logo-that-mexican-place-old.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"3fbd7b03a3d6c6f530f32dab17c71b26"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-5XJ1KGDT8Y","GT-KV6RV49G","GTM-P7TD4BSV"],"gtagConfig":{"anonymize_ip":true,"cookie_expires":0},"pluginConfig":{"head":false,"respectDNT":true,"delayOnRouteUpdate":0,"exclude":[],"origin":"https://www.googletagmanager.com"}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
